import Vue from 'vue/dist/vue.esm.js';

Vue.component('event-checkout', {
    template: '#event-checkout',
    props: {
        maxQuantity: {
            required: true,
            type: Number,
            default: 1
        },
        fixedDiscounts: {
            required: true,
            type: Array,
            default: []
        },
        event: {
            required: true,
            type: Object,
            default: null
        },
        promocodeUrl: {
            required: true,
            type: String,
        },
        locale: {
            required: true,
            type: String,
            default: 'bg'
        },
        currency: {
            required: true,
            type: String,
            default: 'bg'
        }
    },
    data() {
        return {
            totalQuantity: 0,
            totalPrice: 0,
            showPromocode: false,
            hasPromocode: false,
            promocode: null,
            promocodeText: null,
            promocodeError: null,
            promocodeDiscount: 0,
            hasMysteryBoxPrice: this.event.hasMysteryBoxPrice,
            savedAmountPromocode: 0,
            savedAmountMysteryBox: 0,
            savedAmountDiscount: 0,
            originalTotalAmount: 0,

        }
    },
    mounted() {
        this.calcTotalQuantity();
        this.calcDiscounts();
    },

    methods: {
        calcTotalQuantity() {
            this.totalQuantity = 0;

            for (let variant of this.event.variants) {
                this.totalQuantity += variant.quantity;
            }
        },
        incrementQuantity(variant) {
            if (variant.quantity > 99 || this.totalQuantity >= this.maxQuantity || variant.quantity >= variant.actualFreeSpaces) {
                return;
            }

            variant.quantity = parseInt(variant.quantity) + 1;

            this.calcTotalQuantity();
            this.calcDiscounts();

            this.$forceUpdate();

        },
        decrementQuantity(variant) {
            if (variant.quantity === 0 || this.totalQuantity <= 1) {
                return;
            }

            variant.quantity = parseInt(variant.quantity) - 1;

            this.calcTotalQuantity();
            this.calcDiscounts();

            this.$forceUpdate();
        },
        togglePromocodeField() {
            this.showPromocode = !this.showPromocode;
        },
        applyPromocode() {
            let _self = this;
            $.ajax({
                data: {code: _self.promocodeText, eventId: _self.event.id},
                url: _self.promocodeUrl,
                method: 'POST',
                success: function (result) {
                    if (result.success === false) {
                        _self.promocodeError = result.error;
                    } else {
                        let data = (result);
                        _self.promocodeError = null;
                        _self.promocode = data;
                        _self.hasPromocode = true;
                        _self.calcDiscounts()
                    }
                },
            });
        },
        calcPromocodeDiscount() {
            this.savedAmountPromocode = 0;

            if (this.promocode !== null) {
                if (this.promocode.fixed) {
                    this.promocodeDiscount = this.promocode.discount;
                    this.savedAmountPromocode = this.promocode.discount;
                    for (let variant of this.event.variants) {
                        variant.actualPrice = variant.startActualPrice;
                    }
                } else {
                    for (let variant of this.event.variants) {
                        variant.promocodeDiscount = variant.startActualPrice * this.promocode.discount / 100;
                        variant.actualPrice = variant.startActualPrice - variant.promocodeDiscount;
                        variant.savedFromPromocode = (variant.promocodeDiscount * variant.quantity);
                        this.savedAmountPromocode += variant.savedFromPromocode;
                    }
                }

                this.promocodeError = null;
                this.hasPromocode = true;
            } else {
                this.promocodeDiscount = 0;
                this.savedAmountPromocode = 0;
            }
        },
        removePromocodeFromVariants() {
            for (let variant of this.event.variants) {
                variant.promocodeDiscount = 0;
            }
        },
        removePromocode() {
            this.promocodeError = null;
            this.promocode = null;
            this.hasPromocode = false;
            this.promocodeDiscount = 0;
            this.savedAmountPromocode = 0;

            this.removePromocodeFromVariants();
            this.calcDiscounts();
        },
        calcDiscounts() {
            for (let variant of this.event.variants) {
                variant.promocodeDiscount = 0;
                variant.savedFromDiscount = 0;
                variant.savedFromMysteryBox = 0;
                variant.actualPrice = variant.startActualPrice;
            }

            this.savedAmountPromocode = 0;
            this.savedAmountMysteryBox = 0;
            this.savedAmountDiscount = 0;
            this.hasPromocode = false;

            this.calcPromocodeDiscount();

            let activatedDiscount = false;

            if (!this.hasPromocode) {
                for (let variant of this.event.variants) {
                    for (let each of this.fixedDiscounts) {
                        if (each.variant.id === variant.id) {

                            if (each.discountCondition === 'eq' && variant.quantity === each.quantity) {
                                variant.actualPrice = each.price;
                                variant.savedFromDiscount = (variant.originalPrice - each.price) * variant.quantity;
                                this.savedAmountDiscount += variant.savedFromDiscount;
                                activatedDiscount = true;

                                if (this.promocode) {
                                    this.promocodeError = this.promocode.error_discounted_message;
                                    this.hasPromocode = false;
                                    // this.promocode = null
                                    this.promocodeDiscount = 0;
                                    this.savedAmountPromocode = 0;
                                    this.removePromocodeFromVariants();
                                }

                                break;
                            }

                            if (each.discountCondition === 'gt' && variant.quantity > each.quantity) {
                                variant.actualPrice = each.price;
                                variant.savedFromDiscount = (variant.originalPrice - each.price) * variant.quantity;
                                this.savedAmountDiscount += variant.savedFromDiscount;
                                activatedDiscount = true;

                                if (this.promocode) {
                                    this.promocodeError = this.promocode.error_discounted_message;
                                    this.hasPromocode = false;
                                    // this.promocode = null
                                    this.promocodeDiscount = 0;
                                    this.savedAmountPromocode = 0;
                                    this.removePromocodeFromVariants();
                                }

                                break;
                            }
                        }
                    }
                    variant.totalPrice = variant.actualPrice * variant.quantity;
                }

            }

            this.totalPrice = 0;
            this.savedAmountMysteryBox = 0;
            this.originalTotalAmount = 0;

            for (let variant of this.event.variants) {
                this.originalTotalAmount += variant.originalPrice * variant.quantity;
                variant.totalPrice = variant.quantity * variant.actualPrice;
                variant.totalOriginalPrice = variant.quantity * variant.originalPrice;
                this.totalPrice += variant.quantity * variant.actualPrice;
                if (this.hasMysteryBoxPrice && variant.savedFromDiscount <= 0) {
                    this.savedAmountMysteryBox += variant.quantity * (variant.originalPrice - variant.startActualPrice);
                }
            }

            this.totalPrice = this.totalPrice - this.promocodeDiscount;

            this.$forceUpdate();
        },
        formattedVariantPrice(variant) {
            return new Intl.NumberFormat(this.locale, {
                style: 'currency',
                currency: this.currency
            }).format(variant.totalPrice);
        },
        formattedVariantOriginalPrice(variant) {
            return new Intl.NumberFormat(this.locale, {
                style: 'currency',
                currency: this.currency
            }).format(variant.totalOriginalPrice);
        },
        formattedPrice(price) {
            return new Intl.NumberFormat(this.locale, {style: 'currency', currency: this.currency}).format(price);
        },
    },
    computed: {

        fullName: {
            // getter
            get() {
                return this.firstName + ' ' + this.lastName
            },
            // setter
            set(newValue) {
                // Note: we are using destructuring assignment syntax here.
                [this.firstName, this.lastName] = newValue.split(' ')
            }
        }
    }
});